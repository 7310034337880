import Button from '@ui/atoms/buttons/Button'
import TextInput from '@ui/atoms/inputs/TextInput'
import Text from '../atoms/text/Text'
import { neutral, primary } from '@ui/helpers/colors'
import { useCallback, useEffect, useState } from 'react'
import { style } from 'typestyle'
import { Column, Flex, Row } from '../layout/Box'
import { BasicModal, Position } from './modals/BasicModal'
import { useUser } from '../../src/context/user-context'
import { User } from '../../src/types'

const API_URL = '/api/criterion'
const RATINGS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

type Trigger = {
  id: string | number
  triggerProperty: keyof User | 'declinedReviewRetry' | 'reviewFollowUp'
  triggerValue: string | number
  timestamp: string
  product: 'studio'
  description: string
  target: 'new_users' | 'returning_users'
  type: 'string' | 'number' | 'date'
}

type Attempt = {
  id: string
  user_id: string
  reviewed: boolean
  timestamp: string
  product: 'studio'
  platform: string
}

type ResultHandler = (result: { rating: number; reason: string }) => void

type Props = {
  message: string
  onSubmit: ResultHandler
  onChange?: (rating: number) => void
}
const SurveyBox = ({ onSubmit, onChange = () => {}, message }: Props) => {
  const [selected, setSelected] = useState<number>()
  const [reason, setReason] = useState('')
  const submit = useCallback(() => {
    onSubmit({
      rating: selected,
      reason,
    })
  }, [selected, reason])

  useEffect(() => {
    onChange(selected)
  }, [selected])

  const selectionMade = typeof selected === 'number'

  return (
    <Flex>
      <Column align="center" width="100%" gap={16} paddingY={6}>
        <Text.Heading3 text={message} />
        {selectionMade && (
          <form
            onSubmit={(e) => {
              e.preventDefault()
              submit()
            }}
          >
            <Row align="flex-start">
              <TextInput
                placeholder="Help us improve by explaining your score"
                appearance="outline"
                multiline={true}
                width={400}
                maxLength={200}
                hideCharacterCount={true}
                height={40}
                colorWeight={600}
                autoHeight={true}
                onChange={(e) => {
                  setReason(e.target.value)
                }}
              />
              <Button
                marginLeft={6}
                height={40}
                text="Submit"
                onClick={() => submit()}
              />
            </Row>
          </form>
        )}
        <Row gap={12}>
          <Flex width={70} justify="flex-end">
            <Text.Caption1
              text="Not likely"
              color="neutral"
              colorWeight={400}
            />
          </Flex>
          <Row gap={6}>
            {RATINGS.map((x) => {
              const isSelected = x === selected
              return (
                <Flex
                  key={x}
                  className={style({
                    cursor: 'pointer',
                    height: 40,
                    width: 40,
                    borderRadius: '50%',
                    border: '1px solid ' + primary(500),
                    background: isSelected ? primary(500) : 'none',
                    $nest: {
                      '&:hover': {
                        background: primary(500),
                      },
                      '&:active': {
                        background: primary(600),
                      },
                    },
                  })}
                  align="center"
                  justify="center"
                  onClick={() => {
                    setSelected(x)
                  }}
                >
                  {x}
                </Flex>
              )
            })}
          </Row>
          <Flex width={70}>
            <Text.Caption1
              text="Extremely likely"
              color="neutral"
              colorWeight={400}
            />
          </Flex>
        </Row>
      </Column>
    </Flex>
  )
}

// Sort attempts by date ascending
const sortAttempts = (attempts: Attempt[]) =>
  attempts.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
  )

const Trigger = () => {
  // Check whether the user is eligible to be shown the NPS modal
  const user = useUser()
  const [triggers, setTriggers] = useState<Trigger[]>([])
  const [attempts, setAttempts] = useState<Attempt[]>([])
  const [needsSurvey, setNeedsSurvey] = useState(false)
  const [showingPostSurvey, setShowingPostSurvey] = useState(false)
  const [message, setMessage] = useState(
    'How likely are you to recommend Lightstream to someone you know?',
  )

  const processResult = useCallback<ResultHandler>(async (result) => {
    setNeedsSurvey(false)
    setShowingPostSurvey(true)
    sendAttempt(true)
    // Report the review
    fetch(`${API_URL}/reviews`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: user.id,
        rating: result.rating,
        comment: result.reason,
        platform: 'studio2',
        product: 'studio2',
      }),
    }).catch((e) => console.warn(e))
    window.setTimeout(() => {
      setShowingPostSurvey(false)
    }, 2000)
  }, [])

  const processCancel = useCallback(() => {
    setNeedsSurvey(false)
    sendAttempt(false)
  }, [])

  const sendAttempt = useCallback((reviewed: boolean) => {
    return fetch(`${API_URL}/attempts`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: user.id,
        reviewed,
        platform: 'studio2',
        product: 'studio',
      }),
    }).catch((e) => console.warn(e))
  }, [])

  const onRatingSelected = useCallback(async (rating) => {
    if (rating) {
      // Fetch the response text for the given review
      const response = await fetch(`${API_URL}/response?rating=${rating}`, {
        headers: { 'Content-Type': 'application/json' },
      })
        .then((x) => {
          if (x.ok) return x.json()
          throw Error('Request failed')
        })
        .catch(() => null)
      setMessage(response?.message || `How can we improve?`)
    }
  }, [])

  // Fetch attempts/triggers
  useEffect(() => {
    Promise.all([
      fetch(`${API_URL}/triggers`, {
        headers: { 'Content-Type': 'application/json' },
      })
        .then((x) => {
          if (x.ok) return x.json()
          throw Error('Request failed')
        })
        .catch(() => []),
      fetch(`${API_URL}/attempts?userId=${user.id}`, {
        headers: { 'Content-Type': 'application/json' },
      })
        .then((x) => {
          if (x.ok) return x.json()
          throw Error('Request failed')
        })
        .catch(() => []),
    ]).then(([triggers, attempts]) => {
      setTriggers(triggers)
      setAttempts(sortAttempts(attempts))
    })
  }, [])

  // Check whether user needs survey
  useEffect(() => {
    try {
      // Partition triggers by type
      const newUserTriggers = triggers.filter(
        (trigger) => trigger.target !== 'new_users',
      )
      const returningUserTriggers = triggers.filter(
        (trigger) => trigger.target === 'returning_users',
      )
      const declinedReviewRetryTrigger = triggers.find(
        (trigger) => trigger.triggerProperty === 'declinedReviewRetry',
      )
      const reviewFollowUpTrigger = triggers.find(
        (trigger) => trigger.triggerProperty === 'reviewFollowUp',
      )

      const latestAttempt = attempts.at(-1)

      const getUserProperty = (trigger: Trigger) =>
        user[trigger.triggerProperty as keyof User]

      let shouldShow = false

      const checkTriggerMatches = (trigger: Trigger) => {
        if (getUserProperty(trigger)) {
          if (trigger.type === 'date') {
            return (
              Math.ceil(
                (new Date().getTime() -
                  new Date(`${getUserProperty(trigger)}`).getTime()) /
                  (1000 * 3600 * 24),
              ) >= Number(trigger.triggerValue)
            )
          } else if (trigger.type === 'number') {
            return (
              parseInt(getUserProperty(trigger) as string) >=
              Number(trigger.triggerValue)
            )
          } else if (trigger.type === 'string') {
            return getUserProperty(trigger) === trigger.triggerValue
          } else {
            // Trigger type must be invalid somehow.
            return false
          }
        } else {
          return false
        }
      }

      if (!latestAttempt?.reviewed) {
        const userMeetsCriteria = newUserTriggers.some(checkTriggerMatches)

        const attemptMeetsCriteria = () => {
          if (attempts.length > 0) {
            const targetDays = declinedReviewRetryTrigger.hasOwnProperty(
              'triggerValue',
            )
              ? declinedReviewRetryTrigger.triggerValue
              : 21
            const dateDifference = Math.ceil(
              (new Date().getTime() -
                new Date(`${latestAttempt.timestamp}`).getTime()) /
                (1000 * 3600 * 24),
            )
            return dateDifference > targetDays
          } else {
            return true
          }
        }
        shouldShow = userMeetsCriteria && attemptMeetsCriteria()
      } else {
        const userMeetsCriteria =
          returningUserTriggers.some(checkTriggerMatches)

        const attemptMeetsCriteria = () => {
          if (attempts.length > 0) {
            const targetDays = reviewFollowUpTrigger.hasOwnProperty(
              'triggerValue',
            )
              ? reviewFollowUpTrigger.triggerValue
              : 180
            const dateDifference = Math.ceil(
              (new Date().getTime() -
                new Date(`${latestAttempt.timestamp}`).getTime()) /
                (1000 * 3600 * 24),
            )
            return dateDifference > targetDays
          } else {
            return true
          }
        }
        shouldShow = userMeetsCriteria && attemptMeetsCriteria()
      }

      setNeedsSurvey(shouldShow)
    } catch (e) {
      console.warn(e)
    }
  }, [attempts, triggers])

  return (
    <BasicModal
      closeable={!showingPostSurvey}
      position={Position.BottomCenter}
      onClose={() => {
        processCancel()
      }}
      isOpen={needsSurvey || showingPostSurvey}
      shadowBackground={false}
      backgroundColor={neutral(800)}
      textColor={neutral(0)}
    >
      {showingPostSurvey ? (
        <Flex paddingX={50} paddingY={10}>
          <Text.Heading3 text="Thanks for reviewing!" />
        </Flex>
      ) : (
        <SurveyBox
          message={message}
          onSubmit={processResult}
          onChange={onRatingSelected}
        />
      )}
    </BasicModal>
  )
}

export default { Trigger, SurveyBox }
