import React, { useState, useEffect } from 'react'
import { BasicModal } from './BasicModal'
import CustomSelect from '@ui/atoms/inputs/CustomSelect'
import Select from '@ui/atoms/inputs/Select'
import { Flex } from '../../layout/Box'
import Text from '../../atoms/text/Text'
import TextInput from '../../atoms/inputs/TextInput'
import Button from '../../atoms/buttons/Button'
import { countries } from 'countries-list'
import { style } from 'typestyle'
import * as Colors from '../../helpers/colors'

export const nullString = 'null'

type CountryCode = keyof typeof countries
export type CountryFieldValue = CountryCode | typeof nullString

const countriesOptions: { text: string; value: CountryFieldValue }[] = [
  { text: '--Select Your Country--', value: nullString },
  ...Object.entries(countries)
    .map(([code, data]) => ({
      text: data.name,
      value: code as CountryCode,
    }))
    .sort((a, b) => {
      if (a.text > b.text) {
        return 1
      } else {
        return -1
      }
    }),
]

const states = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Armed Forces (AA)',
  'Armed Forces (AE)',
  'Armed Forces (AP)',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Islands',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
] as const

type USState = typeof states[number]

export type StateFieldValue = USState | typeof nullString

const stateOptions: {
  value: StateFieldValue
  text: string
}[] = [
  { value: nullString, text: '---Select your state---' },
  ...states.map((s) => ({
    value: s,
    text: s,
  })),
]

const selectContainerClass = style({
  marginBottom: '14px',
  $nest: {
    '&>select': {
      border: '1px solid rgb(76, 75, 76)',
      borderRadius: '2px',
    },
  },
})

export enum FormFieldName {
  Country = 'country',
  State = 'state',
  PostalCode = 'postalCode',
}

export type FieldValues = {
  [FormFieldName.Country]: CountryFieldValue
  [FormFieldName.State]: StateFieldValue
  [FormFieldName.PostalCode]: string
}

type BaseFormEvent<T extends FormFieldName> = {
  name: T
  isValid: boolean
  value: FieldValues[T]
}

export type FormEvent =
  | BaseFormEvent<FormFieldName.Country>
  | BaseFormEvent<FormFieldName.State>
  | BaseFormEvent<FormFieldName.PostalCode>

type AddressFormProps = {
  defaultCountry?: CountryFieldValue
  defaultPostalCode?: string
  defaultState?: StateFieldValue
  onChange: (event: FormEvent) => void
}

export const BillingAddressForm = (props: AddressFormProps) => {
  const [country, setCountry] = useState<FieldValues[FormFieldName.Country]>(
    props.defaultCountry ?? nullString,
  )
  const [countryDirty, setCountryDirty] = useState(false)
  const [isCountryFocused, setIsCountryFocused] = useState(false)
  const [state, setState] = useState(props.defaultState ?? nullString)
  const [stateDirty, setStateDirty] = useState(false)
  const [isStateFocused, setIsStateFocused] = useState(false)
  const [postalCode, setPostalCode] = useState(props.defaultPostalCode ?? '')
  const [postalCodeDirty, setPostalCodeDirty] = useState(false)
  const [isPostalCodeFocused, setIsPostalCodeFocused] = useState(false)
  useEffect(() => {
    // Set initial form state and validity
    const re = /^\d{5}$/
    props.onChange({
      name: FormFieldName.Country,
      value: country,
      isValid: Boolean(country) && country !== nullString,
    })
    props.onChange({
      name: FormFieldName.State,
      value: state,
      isValid: country !== 'US' || state !== nullString,
    })
    props.onChange({
      name: FormFieldName.PostalCode,
      value: postalCode,
      isValid: country !== 'US' || re.test(postalCode),
    })
  }, [])

  return (
    <Flex
      direction="column"
      align="stretch"
      style={{
        // backgroundColor: Colors.neutral(800),
        borderRadius: '6px',
      }}
      alignSelf="stretch"
      padding={16}
      marginBottom={20}
    >
      <Flex marginBottom={12}>
        <Text.Label text="Country" color="neutral" />
      </Flex>
      <Flex
        direction="column"
        align="stretch"
        alignSelf="stretch"
        marginBottom={15}
      >
        <Select
          textColor="neutral"
          textColorWeight={1000}
          onChange={(e) => {
            const val = e.target.value as CountryFieldValue
            if (val !== 'US') {
              // reset state and postal code
              setState(nullString)
              setStateDirty(false)
              setPostalCode('')
              setPostalCodeDirty(false)
              props.onChange({
                name: FormFieldName.Country,
                value: val,
                isValid: val !== nullString,
              })
              props.onChange({
                name: FormFieldName.State,
                value: nullString,
                isValid: true,
              })
              props.onChange({
                name: FormFieldName.PostalCode,
                value: '',
                isValid: true,
              })
            } else {
              props.onChange({
                name: FormFieldName.Country,
                value: val,
                isValid: true,
              })
              props.onChange({
                name: FormFieldName.State,
                value: nullString,
                isValid: false,
              })
              props.onChange({
                name: FormFieldName.PostalCode,
                value: '',
                isValid: false,
              })
            }
            setCountry(val)
            setCountryDirty(false)
          }}
          appearance="outline"
          value={country}
          options={countriesOptions}
          height={34}
          onFocus={() => setIsCountryFocused(true)}
          onBlur={() => setIsCountryFocused(false)}
        />
      </Flex>
      {country === 'US' && (
        <>
          <Flex marginBottom={12}>
            <Text.Label text="State" color="neutral" />
          </Flex>
          <Flex
            direction="column"
            align="stretch"
            alignSelf="stretch"
            marginBottom={15}
          >
            <Select
              textColor="neutral"
              textColorWeight={1000}
              appearance="outline"
              value={state}
              onChange={(e) => {
                const val = e.target.value as StateFieldValue
                props.onChange({
                  name: FormFieldName.State,
                  value: val,
                  isValid: country !== 'US' || val !== nullString,
                })
                setState(val)
                setStateDirty(true)
              }}
              options={stateOptions}
              height={34}
              onFocus={() => setIsStateFocused(true)}
              onBlur={() => setIsStateFocused(false)}
            />
          </Flex>
          <Flex
            direction="column"
            align="stretch"
            alignSelf="stretch"
            marginBottom={15}
          >
            <TextInput
              label={<Text.Label text="Postal Code" color="neutral" />}
              appearance="outline"
              style={{ alignSelf: 'stretch', justifySelf: 'stretch' }}
              defaultValue={props.defaultPostalCode ?? ''}
              onChange={(evt) => {
                // Five digit US zip code
                const re = /^\d{5}$/
                props.onChange({
                  name: FormFieldName.PostalCode,
                  value: evt.target.value,
                  isValid: country !== 'US' || re.test(evt.target.value),
                })
                setPostalCode(evt.target.value)
                setPostalCodeDirty(true)
              }}
              textColor="neutral"
              textColorWeight={1000}
              onFocus={() => setIsPostalCodeFocused(true)}
              onBlur={() => setIsPostalCodeFocused(false)}
              height={34}
              invalid={country === 'US' && !/^\d{5}$/.test(postalCode)}
            />
          </Flex>
        </>
      )}
    </Flex>
  )
}
