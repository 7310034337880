import * as Magic from 'magic-sdk'

type MagicEvents = {
  'email-sent': () => void
  'email-not-deliverable': () => void
  retry: () => void
}

export const loginWithEmail = async (
  magic: Magic.Magic,
  email: string,
  options?: Partial<MagicEvents>,
) => {
  try {
    const redirectURL = new URL('/v2', window.location.origin)
    redirectURL.searchParams.set('email', email)
    const req = magic.auth.loginWithMagicLink({
      email,
      showUI: false,
      redirectURI: redirectURL.href,
    })
    if (options) {
      Object.entries(options).forEach(
        <K extends keyof MagicEvents>([key, val]: [K, MagicEvents[K]]) => {
          req.on(key, val)
        },
      )
    }
    return req
  } catch (error) {
    console.error(error)
  }
}

// NO session, NO magic -> modal giving users choice of continue to magic or login studio 1
// NO session, YES magic -> auth in with magic (creates session, gets/creates user)
// YES session, NO magic -> tell them to login with magic
// YES session, YES magic -> load up studio 2
