import React, { useContext, useEffect, useState } from 'react'
import { init, Helpers } from '@api.stream/studio-kit'
import { DEFAULT_LAYOUT, layouts } from '../utils/scene-layouts'
import { LoginWrapper } from './Login'
import { AppContext, StudioContext } from '../context/app-context'
import { UserContext } from '../context/user-context'
import { Column, Flex, Row } from '@ui/layout/Box'
import { LoadingSpinner } from '@ui/atoms/animations/LoadingSpinner'
import { SVG } from '@ui/atoms/icons/Icon'
import { Project } from '../components/Project'
import Text from '@ui/atoms/text/Text'
import { VideoBannerStyle } from '@ui/components/VideoBannerStyle'
import { NormalModal } from '@ui/atoms/FloatingMenu/Modal'
import { CameraSettings } from '@ui/components/CameraSettings'
import { PermissionAlerter } from '../components/Permissions'
import { UpdateFormModal } from './account/UpdateBillingAddress'
import Bowser from 'bowser'
import NPS from '@ui/components/NPS'
import * as Types from '../types'
import { hasPermission, Permission } from '../../src/utils/permissions'

const browser = Bowser.getParser(navigator.userAgent)

const { ScenelessProject } = Helpers

const isBillingAddressValid = (user: Types.User) => {
  if (!user?.subscription) return true
  if (user?.subscription && !user.subscription.billingAddress) return false
  if (user?.subscription && user.subscription.billingAddress) {
    const { country, state, zip } = user.subscription.billingAddress
    if (country && country !== 'US') return true
    if (!state || !zip) return false
  }
  return true
}

export const HostView = () => {
  const { studio, room, setRoom, setStudio, setWebcamId, setMicrophoneId } =
    useContext(StudioContext)
  const user = useContext(UserContext)
  const billingAddressValid = isBillingAddressValid(user)
  const app = useContext(AppContext)
  const [failure, setFailure] = useState<string>(null)
  const [displayName, setDisplayName] = useState('')
  const [disposeSettings, setDisposeSettings] = useState(() => () => {})

  useEffect(() => {
    if (room) {
      room.sendData({ type: 'UserJoined' }).catch((e) => {
        console.warn(e)
      })
    }
  }, [room])

  useEffect(() => {
    if (!app.loaded) return
    init({
      env: app.sdkEnv,
      logLevel: app.sdkEnv === 'stage' ? 'Debug' : 'Warn',
    })
      .then(setStudio)
      .catch((e) => {
        console.warn('Failed to initialize', e)
        setFailure(e.message)
      })
  }, [app.loaded])

  useEffect(() => {
    if (!studio) return
    window.studio = studio // Debug helper
  }, [studio])

  useEffect(() => {
    if (!studio || !user.sdkToken || app.project) return

    // Log in
    studio
      .load(user.sdkToken, {
        x: user?.resolution?.x || 1280,
        y: user?.resolution?.y || 720,
      })
      .then(async (sdkUser) => {
        // If there's a project, return it - otherwise create one
        let project = sdkUser.projects[0]
        if (!project) {
          const { layout, props } = layouts[DEFAULT_LAYOUT]
          project = await ScenelessProject.create(
            {
              backgroundImage:
                'https://studio.golightstream.com/images/polygons.jpg',
              layout,
              layoutProps: props,
            },
            // Store defaults in metadata for future reference
            {
              layout: DEFAULT_LAYOUT,
              bannerStyle: VideoBannerStyle.DEFAULT,
              primaryColor: '#F39508',
              showNameBanners: true,
              logoPosition: 'top-right',
            },
            {
              x: user?.resolution?.x || 1280,
              y: user?.resolution?.y || 720,
            },
          )
        }

        // Recreate the layout on the project entirely if it does not have a version
        if (!project.scene.getRoot().props.version) {
          await studio.Command.recreateLayout({
            projectId: project.id,
          })
        }

        await studio.Command.setActiveProject({
          projectId: project.id,
        })

        window.projectCommands = ScenelessProject.commands(project) // Debug helper
        window.get = project.scene.get
      })
      .catch((e) => {
        console.warn(e)
        setFailure(e.message)
        localStorage.removeItem('token')
      })
  }, [studio, user.sdkToken, app.project, user.resolution])

  useEffect(() => {
    if (!app.projectCommands || !room) return
    // Prune non-existent participants from the project
    app.projectCommands.pruneParticipants()
  }, [app.projectCommands, room])

  if (app.project) {
    return (
      <>
        {room ? (
          <>
            <Project />
            {app.NPS.enabled && <NPS.Trigger />}
          </>
        ) : (
          <Row style={{ height: '100%' }} justify="center">
            <Column align="center" style={{ marginTop: '-15%' }}>
              <SVG
                color="primary"
                colorWeight={500}
                height={140}
                width={140}
                svg={LoadingSpinner}
              />
              <Text.Caption1 text="Joining Green Room..." />
            </Column>
          </Row>
        )}

        {!displayName && (
          <NormalModal isOpen={true} close={() => {}}>
            <Column padding={20}>
              <Flex marginBottom={20}>
                <Text.Heading1 text="Settings" />
              </Flex>
              <PermissionAlerter />
              <CameraSettings
                buttonText="Join"
                setDispose={(fn) => setDisposeSettings(() => fn)}
                onComplete={(settings) => {
                  // Note: Uncertain why this is necessary only in chrome
                  if (browser.isBrowser('chrome')) disposeSettings()

                  window.setTimeout(() => {
                    const displayName = settings.displayName || 'Host'
                    setDisplayName(displayName)
                    setWebcamId(settings.webcamId)
                    setMicrophoneId(settings.microphoneId)
                    app.project
                      .joinRoom({
                        displayName,
                      })
                      .then(async (room) => {
                        setRoom(room)
                        window.room = room // Debug helper
                        const participant = room.getParticipant(
                          room?.participantId,
                        )
                        if (participant) {
                          if (
                            hasPermission(
                              app.project.role,
                              Permission.ManageGuests,
                            )
                          ) {
                            /* Setting the participant metadata to the room. */
                            return room.setParticipantMetadata(
                              room?.participantId,
                              {
                                ...participant.meta,
                                isMirrored: settings.isMirrored,
                              },
                            )
                          }
                        }
                      })
                  })
                }}
              />
            </Column>
          </NormalModal>
        )}
        {!billingAddressValid && <UpdateFormModal />}
      </>
    )
  }
  if (!app.loaded) {
    return (
      <Row style={{ height: '100%' }} justify="center">
        <Column align="center" style={{ marginTop: '-15%' }}>
          <SVG
            color="primary"
            colorWeight={500}
            height={140}
            width={140}
            svg={LoadingSpinner}
          />
          <Text.Caption1 text="Loading..." />
        </Column>
      </Row>
    )
  }
  if (!user.loggedIn || !user.emailVerified) {
    return <LoginWrapper mode={'register'} />
  }
  if (failure) {
    return <div>Failed to initialize: `{failure}`</div>
  }
  return (
    <Row style={{ height: '100%' }} justify="center" align="center">
      <Column align="center" style={{ marginTop: '-15%' }}>
        <SVG
          color="primary"
          colorWeight={500}
          height={140}
          width={140}
          svg={LoadingSpinner}
        />
        <Text.Caption1 text="Preparing project..." />
      </Column>
    </Row>
  )
}
