import React, {
  useState,
  useMemo,
  useReducer,
  useEffect,
  useContext,
} from 'react'
import { UserContext } from '../../context/user-context'
import * as Types from '../../types'
import {
  CountryFieldValue,
  FormFieldName,
  StateFieldValue,
  BillingAddressForm,
  FormEvent,
  nullString,
} from '@ui/components/modals/BillingAddressModal'
import TextInput from '@ui/atoms/inputs/TextInput'
import Button from '@ui/atoms/buttons/Button'
import { Flex } from '@ui/layout/Box'
import { CustomText } from '@ui/atoms/text/Text'
import {
  BasicModal,
  modalHeading,
  modalContent,
} from '@ui/components/modals/BasicModal'
import { LoadingSpinner } from '@ui/atoms/animations/LoadingSpinner'
import { Checkmark } from '@ui/atoms/animations/Checkmark'
import { trigger, User } from '../../utils/events'
import { SVG } from '@ui/atoms/icons/Icon'

const loadingText = 'Updating billing information...'
const successText = 'Your billing information has been updated!'

type UpdateFormState = {
  [FormFieldName.Country]: {
    value: CountryFieldValue
    valid: boolean
  }
  /**
   * undefined unless country === 'US'
   */
  [FormFieldName.State]: {
    value: StateFieldValue
    valid: boolean
  }
  /**
   * undefined unless country === 'US'
   */
  [FormFieldName.PostalCode]: {
    value: string
    valid: boolean
  }
}

const updateFormReducer: React.Reducer<UpdateFormState, FormEvent> = (
  prevState,
  action,
) => {
  const state = {
    ...prevState,
  }
  switch (action.name) {
    case FormFieldName.Country:
      state.country.value = action.value
      state.country.valid = action.isValid
      break
    case FormFieldName.State:
      state.state.value = action.value
      state.state.valid = action.isValid
      break
    case FormFieldName.PostalCode:
      state.postalCode.value = action.value
      state.postalCode.valid = action.isValid
      break
    default:
      break
  }
  return state
}

const emptyAddressState: UpdateFormState = {
  [FormFieldName.Country]: {
    value: nullString,
    valid: false,
  },
  [FormFieldName.State]: {
    value: nullString,
    valid: true,
  },
  [FormFieldName.PostalCode]: {
    value: '',
    valid: true,
  },
}

const defaultAddress = (user: Types.User): UpdateFormState => {
  if (user.subscription) {
    const address: Partial<{
      country: CountryFieldValue
      state: StateFieldValue
      zip: string
    }> = {
      country: (user?.subscription?.billingAddress?.country ??
        undefined) as CountryFieldValue,
      state: (user?.subscription?.billingAddress?.state ??
        undefined) as StateFieldValue,
      zip: user?.subscription?.billingAddress?.zip,
    }

    const country = address.country ?? nullString
    const addressState = address.state
    const state = country !== 'US' ? nullString : addressState
    const postalCode = country !== 'US' ? '' : address.zip ?? ''
    const postalCodeRE = /^\d{5}$/
    const postalCodeValid = country !== 'US' || postalCodeRE.test(postalCode)
    return {
      [FormFieldName.Country]: {
        value: country,
        valid: country !== nullString,
      },
      [FormFieldName.State]: {
        value: state,
        valid: country !== 'US' || state !== nullString,
      },
      [FormFieldName.PostalCode]: {
        value: postalCode,
        valid: postalCodeValid,
      },
    }
  } else {
    return emptyAddressState
  }
}

type AddressUpdateFormProps = {
  user: Types.User
  onSuccess: (response: {
    success: boolean
    payload: {
      billingAddress: Types.User['subscription']['billingAddress']
    }
  }) => any
  onFailure: (e: { success: boolean; payload: string }) => any
  cleanup: () => any
}

const enum FormState {
  Active = 'active',
  Loading = 'loading',
  Success = 'success',
}

/**
 * Just the address form on its own, without payment form.
 */
const BillingAddressUpdateForm = (props: AddressUpdateFormProps) => {
  const [timer, setTimer] = useState(null)
  const [formState, setFormState] = useState<FormState>(FormState.Active)
  const [addressState, dispatchAddressFormEvent] = useReducer(
    updateFormReducer,
    defaultAddress(props.user),
  )
  const [waiting, setWaiting] = useState(false)
  const originalState = useMemo(() => {
    return addressState
  }, [])
  const onChange = useMemo(
    () => (event: FormEvent) => {
      dispatchAddressFormEvent(event)
    },
    [dispatchAddressFormEvent],
  )
  const cleanup = useMemo(
    () => () => {
      props.cleanup()
    },
    [],
  )
  useEffect(() => {
    if (formState === FormState.Success) {
      setTimer(
        setTimeout(() => {
          cleanup()
        }, 3000),
      )
    }
  }, [formState])
  return (
    <>
      {formState === FormState.Active && (
        <Flex direction="column" align="flex-start">
          <BillingAddressForm
            onChange={onChange}
            defaultCountry={originalState.country.value}
            defaultState={originalState.state.value}
            defaultPostalCode={originalState.postalCode.value}
          />
          <Flex alignSelf="center">
            <Button
              text="Update"
              onClick={async () => {
                setWaiting(true)
                setFormState(FormState.Loading)

                const body = {
                  lightstreamUserID: props.user.id,
                  address: {
                    country: addressState.country.value,
                    state: addressState.state.value,
                    zip: addressState.postalCode.value,
                  },
                }

                const response = await fetch(
                  `/api/users/${props.user.id}/subscriptions/address`,
                  {
                    method: 'PATCH',
                    body: JSON.stringify(body),
                  },
                )
                const responseBody = await response.json()
                if (responseBody.success) {
                  setFormState(FormState.Success)
                  setWaiting(false)
                  // TODO: success animation
                  props.onSuccess(responseBody)
                } else {
                  props.onFailure(responseBody)
                }
              }}
              disabled={
                waiting ||
                !addressState.country.valid ||
                !addressState.state.valid ||
                !addressState.postalCode.valid
              }
            />
          </Flex>
        </Flex>
      )}
      {formState === FormState.Loading && (
        <Flex direction="column" justify="center" align="center" width={'100%'}>
          <SVG
            color="primary"
            colorWeight={500}
            height={100}
            width={100}
            svg={LoadingSpinner}
          />
          <Flex
            justify="center"
            marginTop="large"
            style={{ textAlign: 'center' }}
          >
            <CustomText
              color="neutral"
              colorWeight={1000}
              fontWeight={700}
              fontSize={24}
              text={loadingText}
            />
          </Flex>
        </Flex>
      )}
      {formState === FormState.Success && (
        <Flex direction="column" justify="center" align="center" width={'100%'}>
          <SVG
            color="primary"
            colorWeight={500}
            height={100}
            width={100}
            svg={Checkmark}
          />
          <Flex
            justify="center"
            marginTop="large"
            style={{ textAlign: 'center' }}
          >
            <CustomText
              color="neutral"
              colorWeight={1000}
              fontWeight={700}
              fontSize={24}
              text={successText}
            />
          </Flex>
        </Flex>
      )}
    </>
  )
}

enum ModalState {
  Intro = 'intro',
  Active = 'active',
  Failure = 'failure',
}

export const UpdateFormModal = () => {
  const user = useContext(UserContext)
  const [state, setState] = useState(ModalState.Intro)
  const [failure, setFailure] = useState('')
  const onSuccess: AddressUpdateFormProps['onSuccess'] = (response) => {
    // TODO: Update user billing address in local state
    trigger(User.UpdatedBillingAddress, {
      ...user.subscription,
      billingAddress: response.payload.billingAddress,
    })
  }
  const onFailure: AddressUpdateFormProps['onFailure'] = (response) => {
    setState(ModalState.Failure)
    setFailure(response.payload)
  }

  return (
    <BasicModal
      onClose={() => {}}
      isOpen={true}
      closeable={false}
      headline={'Update your billing information'}
    >
      {state === ModalState.Intro && (
        <>
          <p>
            Please update your billing information to continue using Lightstream
            Studio.
          </p>
          <br />
          <p>
            Depending on jurisdiction, your payments may be subject to sales tax
            going forward.
          </p>
          <Button
            marginTop={12}
            text="OK"
            onClick={() => setState(ModalState.Active)}
          />
        </>
      )}
      {state === ModalState.Active && (
        <BillingAddressUpdateForm
          user={user}
          onSuccess={onSuccess}
          onFailure={onFailure}
          cleanup={() => {}}
        />
      )}
      {state === ModalState.Failure && (
        <>
          <p>{failure}</p>
          <Button
            color="secondary"
            marginTop={12}
            text="Retry"
            onClick={() => setState(ModalState.Active)}
          />
        </>
      )}
    </BasicModal>
  )
}
