import React, { useState, useReducer, useContext, useCallback } from 'react'
import { Account } from '../types'
import { UserContext } from '../context/user-context'
import { Flex, Row, Column } from '../../ui/layout/Box'
import { CustomText, TextBuilder } from '../../ui/atoms/text/Text'
import { loginWithEmail } from '../utils/email-login'
import { Avatar } from '../../ui/atoms/icons/Avatar'
import Button from '../../ui/atoms/buttons/Button'
import Swapper from '../../ui/components/Swapper'
import Icon from '@ui/atoms/icons/Icon'
import TextInput from '@ui/atoms/inputs/TextInput'
import Link from '@ui/atoms/Link'
import { style } from 'typestyle'
import imgUrl from '../../images/foliage_rotate.jpg'
import { hideEmail, sendMagicLogs } from '../utils/magiclink-log'
import { useApp } from '../context/app-context'
type Mode = 'login' | 'register' | 'validate' | 'add'

const modeReducer: React.Reducer<Mode, Mode> = (mode: Mode) => mode

type State = {
  mode: 'login' | 'register' | 'validate' | 'add'
  /**
   * User ID - only present if mode is 'valdiate' or 'add'
   */
}

type LoginFormProps = {
  switchMode: (mode: State['mode']) => void
  sendAuthEmail: (email: string) => Promise<void>
}
export const LoginForm = (props: LoginFormProps) => {
  const [email, setEmail] = useState('')
  const [activeView, setActiveView] = useState<
    'form' | 'emailSent' | 'emailResent'
  >('form')

  const descriptionText =
    activeView === 'form' ? '' : 'We sent your login link to:'

  return (
    <Flex direction="column" align="center" width="100%">
      <Row justify="center" marginTop="small" marginBottom="large">
        <CustomText
          text="Login"
          textAlign="center"
          width={172}
          style={{ fontSize: 18, lineHeight: 1.2 }}
        />
      </Row>
      {descriptionText && (
        <>
          <Row marginTop="large" marginBottom="large" justify="center">
            <CustomText
              color="neutral"
              colorWeight={300}
              text={descriptionText}
              textAlign="center"
            />
          </Row>
          <Row>
            <CustomText text={email} />
          </Row>
        </>
      )}
      <Swapper
        active={activeView}
        items={{
          form: (
            <Flex align="center" direction="column" width="100%">
              <EmailForm
                label="Lightstream Email Account"
                onSubmit={(email) => {
                  setEmail(email)
                  return props.sendAuthEmail(email).then(() => {
                    setActiveView('emailSent')
                  })
                }}
              />
              <Row marginBottom={12} marginTop="large" justify="center">
                <CustomText text="Or log in with" />
              </Row>
              <Row marginTop="huge" justify="center">
                <CustomText text="Don't have a Lightstream email account?" />
              </Row>
              <Row marginTop="small" justify="center">
                <Button
                  fontSize={11}
                  fontWeight={700}
                  appearance="text"
                  text="Register an account"
                  onClick={() => {
                    props.switchMode('register')
                  }}
                />
              </Row>
            </Flex>
          ),
          emailSent: (
            <Flex
              align="center"
              direction="column"
              width="100%"
              style={{ position: 'relative' }}
            >
              <Row justify="center">
                <span>
                  <CustomText
                    fontSize={14}
                    color="neutral"
                    colorWeight={300}
                    text="Didn't get an email?"
                  />
                </span>
                <Button
                  fontSize={11}
                  fontWeight={500}
                  appearance="text"
                  text="Resend"
                  style={{ cursor: 'pointer', marginLeft: '0.3em' }}
                  onClick={() => {
                    props.sendAuthEmail(email).then(() => {
                      setActiveView('emailResent')
                    })
                  }}
                />
              </Row>
              <Row style={{ position: 'absolute', bottom: -24 }}>
                <Button
                  appearance="text"
                  fontWeight={700}
                  text="Back to login page"
                  icon="ChevronLeft"
                  fontSize={13}
                  iconSize={11}
                  iconMargin={4}
                  onClick={() => {
                    setActiveView('form')
                  }}
                />
              </Row>
            </Flex>
          ),
          emailResent: (
            <Flex align="center" direction="column" width="100%">
              <Row marginTop="large" justify="center">
                <span>
                  <CustomText text="Email resent" />
                </span>
              </Row>
              <Row style={{ position: 'absolute', bottom: '-50px' }}>
                <Button
                  appearance="text"
                  fontWeight={400}
                  text="Back to login page"
                  fontSize={14}
                  iconSize={11}
                  iconMargin={4}
                  onClick={() => {
                    setActiveView('form')
                  }}
                />
              </Row>
            </Flex>
          ),
        }}
      />
    </Flex>
  )
}

type LoginWrapperProps = {
  mode?: State['mode']
}

const darkGradient = style({
  position: 'absolute',
  height: '100%',
  width: '100%',
  background: '#212021',
  opacity: 0.5,
})

const assetURL = import.meta?.url
  ? new URL(imgUrl, import.meta.url).href
  : imgUrl

const foliageBackground = style({
  position: 'absolute',
  height: '100%',
  width: '100%',
  background: `linear-gradient(0deg, #26AD80, #26AD80), url('${assetURL}')`,
  backgroundBlendMode: `color, normal`,
  filter: 'blur(10px)',
  backgroundSize: 'cover',
})

export const LoginWrapper = (props: LoginWrapperProps) => {
  const app = useApp()
  const user = useContext(UserContext)

  const [sentEmail, setSentEmail] = useState(false)

  const sendAuthEmail = useCallback(async (email: string) => {
    return new Promise((resolve, reject) => {
      loginWithEmail(app.magicInstance, email, {
        'email-sent': async () => {
          setSentEmail(true)
          sendMagicLogs({
            message: {
              state: 'email-sent',
              email: hideEmail(email),
            },
          })
          resolve(true)
        },
        'email-not-deliverable': async () => {
          sendMagicLogs({
            message: {
              state: 'email-not-deliverable',
              email: hideEmail(email),
            },
          })
          resolve(false)
        },
      })
        .then(async (result) => {
          resolve(true)
        })
        .catch(async (e) => {
          sendMagicLogs({
            message: {
              state: 'email-delivery-failed',
              reason: e.rawMessage || e.message,
              email: hideEmail(email),
            },
          })
          console.warn(e)
        })
    })
  }, [app.magicInstance])

  const initMode = () => {
    if (sentEmail) {
      return 'validate'
    }
    if (!user.accounts) {
      return 'register'
    }
    if (!user.accounts.find((t) => t.type === 'magic')) {
      return 'add'
    }
    return 'login'
  }

  const [mode, setMode] = useReducer(modeReducer, initMode())

  return (
    <Flex
      style={{
        minHeight: '100vh',
      }}
      align="center"
      justify="center"
      height="100%"
      width="100%"
      direction="column"
    >
      <div className={foliageBackground} />
      <div className={darkGradient} />

      <div style={{ position: 'absolute', top: 41, left: 41 }}>
        <Button
          appearance="text"
          color="neutral"
          colorWeight={0}
          text="GOLIGHTSTREAM.COM"
          fontSize={13}
          fontWeight={300}
          href="https://golightstream.com"
        />
      </div>
      <LoginContainer>
        <Column width={250} align="center">
          {/*Branding*/}
          <Row justify="center" marginBottom={40} marginTop={18}>
            <CustomText text="Studio 2" fontSize={40} fontWeight={900} />
          </Row>
          <Swapper
            active={mode}
            items={{
              register: (
                <RegisterForm
                  sendAuthEmail={async (email) => {
                    await sendAuthEmail(email)
                    setSentEmail(true)
                  }}
                  switchMode={(mode) => {
                    setMode(mode)
                  }}
                  mode="register"
                />
              ),
              login: (
                <LoginForm
                  sendAuthEmail={async (email) => {
                    await sendAuthEmail(email)
                    setSentEmail(true)
                  }}
                  switchMode={setMode}
                />
              ),
              validate: (
                // TODO: Implement
                <RegisterForm
                  sendAuthEmail={async (email) => {
                    await sendAuthEmail(email)
                    setSentEmail(true)
                  }}
                  switchMode={setMode}
                  mode="validate"
                />
              ),
              add: (
                // TODO: Implement
                <RegisterForm
                  sendAuthEmail={async (email) => {
                    await sendAuthEmail(email)
                    setSentEmail(true)
                  }}
                  switchMode={setMode}
                  mode="add"
                />
              ),
            }}
          />
        </Column>
      </LoginContainer>
      <div style={{ position: 'absolute', bottom: 32 }}>
        <Footer />
      </div>
    </Flex>
  )
}

type EmailState = {
  isUserLoggedIn: boolean
  verificationSent: boolean
  sendAuthEmail: (email: string) => Promise<void>
  mode: 'login' | 'register' | 'validate' | 'add'
  /**
   * User ID - only present if mode is 'valdiate' or 'add'
   */
  id?: string
}

const validateEmailInput = (
  input: HTMLInputElement,
): {
  valid: boolean
  email: string
  message?: string
} => {
  const email = input.value
  let valid = input.checkValidity()
  let message
  if (!email) {
    message = 'Please enter a valid email address.'
  } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(email)) {
    message = 'Please enter a valid email address.'
    valid = false
  } else if (!valid) {
    message = input.validationMessage
  }
  return {
    valid,
    email,
    message,
  }
}

type EmailFormProps = {
  onSubmit: (email: string) => Promise<void>
  label?: string
}
export const EmailForm = (props: EmailFormProps) => {
  const [email, setEmail] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [pending, setPending] = useState(false)
  const [message, setMessage] = useState('Please enter a valid email address.')
  const [warning, setWarning] = useState('')
  const [error, setError] = useState('')

  return (
    <form
      noValidate
      style={{ width: '100%' }}
      onSubmit={(e) => {
        e.preventDefault()
        if (pending) return
        if (!isValid) return setWarning(message)
        setPending(true)
        props.onSubmit(email).catch((e) => {
          console.warn(e)
          setWarning('')
          setError(e.message)
          setPending(false)
        })
      }}
    >
      <Flex align="center" direction="column">
        {error && (
          <Row marginBottom="medium">
            <TextBuilder
              text={[
                [error],
                ['Please double check your email and try again.'],
              ]}
            />
          </Row>
        )}
        {warning && (
          <Row marginBottom="medium">
            <CustomText text={warning} color="secondary" colorWeight={400} />
          </Row>
        )}
        {props.label && (
          <Row marginBottom="medium">
            <CustomText text={props.label} />
          </Row>
        )}
        <Row marginBottom={12}>
          <TextInput
            appearance="outline"
            color="neutral"
            colorWeight={400}
            type="email"
            height={40}
            width={250}
            required={true}
            autoFocus={true}
            invalid={!isValid && Boolean(warning)}
            placeholder="Enter email address"
            onChange={(e) => {
              const { valid, message, email } = validateEmailInput(
                e.target as HTMLInputElement,
              )
              setIsValid(valid)
              setMessage(message)
              if (warning) {
                setWarning(message)
              }
              setError('')
              setEmail(email)
            }}
          />
        </Row>
        <Row>
          <Button
            disabled={pending}
            loading={pending}
            submit={true}
            height={40}
            width={250}
            text="Send Login Link"
            fontSize={16}
            fontWeight={700}
          />
        </Row>
      </Flex>
    </form>
  )
}

type RegisterFormProps = {
  switchMode: (mode: EmailState['mode']) => void
  sendAuthEmail: (email: string) => Promise<void>
  mode: EmailState['mode']
}
export const RegisterForm = (props: RegisterFormProps) => {
  const user = useContext(UserContext)
  const [email, setEmail] = useState('')
  const [activeView, setActiveView] = useState<
    'form' | 'emailSent' | 'emailResent'
  >('form')

  const firstUserAccount = user?.accounts ? user.accounts[0] : null

  const headerText =
    activeView === 'form'
      ? 'Register your Lightstream account'
      : 'Check your email'

  const apology =
    'We’re moving to email-based accounts. Completing this step will link your Lightstream account to this email.'

  return (
    <Flex align="center" direction="column" width="100%">
      {activeView !== 'form' && (
        <Row justify="center" marginBottom={28}>
          <CustomText fontWeight={900} fontSize={20} text="Check your email" />
        </Row>
      )}
      {activeView === 'form' && props.mode === 'register' && (
        <Row justify="center" marginBottom={28}>
          <CustomText fontWeight={900} fontSize={20} text="Log In" />
        </Row>
      )}
      {activeView === 'form' && props.mode === 'add' && (
        <>
          {Boolean(firstUserAccount) && (
            <>
              <Row align="center" justify="center" marginBottom={12}>
                {/* @ts-ignore */}
                {Boolean(firstUserAccount.logo) && (
                  <>
                    {/* @ts-ignore */}
                    <Avatar size={48} src={firstUserAccount.logo} />
                  </>
                )}
                <CustomText
                  marginLeft={9}
                  text={firstUserAccount.displayName}
                  fontSize={15}
                  fontWeight={900}
                />
              </Row>
            </>
          )}
          <Row marginBottom={28}>
            <CustomText
              fontWeight={900}
              fontSize={20}
              text="Verify your email"
            />
          </Row>
        </>
      )}
      {activeView !== 'form' && (
        <>
          <Row marginBottom={4} justify="center">
            <CustomText
              fontSize={14}
              fontWeight={400}
              color="neutral"
              colorWeight={300}
              text={'We sent your login link to:'}
              textAlign="center"
            />
          </Row>
          <Row marginBottom={28}>
            <CustomText text={email} fontSize={14} fontWeight={700} />
          </Row>
        </>
      )}
      <Swapper
        active={activeView}
        items={{
          form: (
            <Flex align="center" direction="column" width="100%">
              <EmailForm
                onSubmit={(email) => {
                  setEmail(email)
                  return props.sendAuthEmail(email).then(() => {
                    setActiveView('emailSent')
                  })
                }}
              />
              {props.mode === 'add' && (
                <Row marginTop="huge" justify="center" width={275}>
                  <span>
                    <CustomText
                      textAlign="center"
                      text={apology}
                      fontSize={11}
                    />
                  </span>
                </Row>
              )}
            </Flex>
          ),
          emailSent: (
            <Flex align="center" direction="column" width="100%">
              <Row justify="center">
                <span>
                  <CustomText
                    fontSize={14}
                    fontWeight={400}
                    color="neutral"
                    colorWeight={300}
                    text="Didn't get an email?"
                  />
                </span>
                <span style={{ marginLeft: '0.3em' }}>
                  <Button
                    fontSize={14}
                    fontWeight={400}
                    appearance="text"
                    text="Resend"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      props.sendAuthEmail(email).then(() => {
                        setActiveView('emailResent')
                      })
                    }}
                  />
                </span>
              </Row>
              <Row style={{ position: 'absolute', bottom: '-50px' }}>
                <Button
                  appearance="text"
                  fontWeight={400}
                  text="Back to login page"
                  fontSize={14}
                  iconSize={11}
                  iconMargin={4}
                  onClick={() => {
                    setActiveView('form')
                  }}
                />
              </Row>
            </Flex>
          ),
          emailResent: (
            <Flex align="center" direction="column" width="100%">
              <Row marginTop="large" justify="center">
                <span>
                  <CustomText text="Email resent" />
                </span>
              </Row>
              <Row marginTop="huge">
                <Button
                  appearance="text"
                  fontWeight={700}
                  text="Back to login page"
                  icon="ChevronLeft"
                  fontSize={13}
                  iconSize={11}
                  iconMargin={4}
                  onClick={() => {
                    setActiveView('form')
                  }}
                />
              </Row>
            </Flex>
          ),
        }}
      />
    </Flex>
  )
}

/**
 * A footer containing Lightstream logo and terms of use links
 */
export const Footer = () => {
  return (
    <Flex align="center" direction="column">
      <Row justify="center">
        <Icon
          color="neutral"
          colorWeight={0}
          width={133}
          height={17}
          name="LightstreamWithText"
        />
      </Row>
      <Row marginTop={14} justify="center">
        <Link
          color="neutral"
          colorWeight={350}
          fontSize={11}
          fontWeight={400}
          href="https://golightstream.com/terms-of-use"
          text="Terms of Use"
        />
        <Link
          color="neutral"
          colorWeight={350}
          fontSize={11}
          fontWeight={400}
          href="https://golightstream.com/privacy-policy"
          text="Privacy Policy"
          marginLeft={14}
        />
      </Row>
    </Flex>
  )
}

const leftBracketStyle = style({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: 130,
  borderLeft: '1px solid #26AD80',
  borderTop: '1px solid #26AD80',
  borderTopLeftRadius: '3px',
  borderBottomLeftRadius: '3px',
  pointerEvents: 'none',
})

const rightBracketStyle = style({
  position: 'absolute',
  top: 0,
  right: 0,
  height: '100%',
  width: 130,
  borderRight: '1px solid #26AD80',
  borderTop: '1px solid #26AD80',
  borderTopRightRadius: '3px',
  borderBottomRightRadius: '3px',
  pointerEvents: 'none',
})

const LeftBracket = () => <div className={leftBracketStyle} />

const RightBracket = () => <div className={rightBracketStyle} />

/**
 * Wraps a child in a container with login styles
 */
type LoginContainerProps = {
  children: React.ReactChild
  width?: number | string
  height?: number | string
}
export const LoginContainer = ({
  children,
  width,
  height,
}: LoginContainerProps) => {
  return (
    <Flex
      minWidth={100}
      minHeight={60}
      width={width}
      paddingTop={26}
      paddingBottom={26}
      paddingX={40}
      marginTop={25}
      style={{
        background: 'rgba(33, 32, 33, 0.5)',
        borderBottom: '1px solid #26AD80',
        filter: 'drop-shadow(rgba(38, 173, 128, 0.2) 0 0 60px)',
        borderRadius: '3px',
        position: 'relative',
      }}
    >
      <LeftBracket />
      <RightBracket />
      {/*LS Icon*/}
      <span
        style={{
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translate3d(-50%, -50%, 0)',
        }}
      >
        <Icon name="Lightstream" color="primary" colorWeight={500} width={52} />
      </span>
      {children}
    </Flex>
  )
}
