import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { HostView } from './host/HostView'
import { AppProvider } from './context/app-context'
import { UserProvider } from './context/user-context'
import { Helpers } from '@api.stream/studio-kit'
import './index.css'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Integration } from '@sentry/types'
import { GuestChatProvider } from './context/guest-chat-context'
import { PermissionProvider } from './context/permission-context'
import { UserType } from './types'

Sentry.init({
  dsn: 'https://fe2c8041220e42deaf93066c78055f8d@o100656.ingest.sentry.io/6505836',
  integrations: [new BrowserTracing() as unknown as Integration],
  environment: 'local', // We'll change this scope after we receive environment information
  tracesSampleRate: 1.0,
})

// Is there a cleaner way to do this?
const setFavicon = () => {
  const isStage = window.location.origin.indexOf('stream.horse') > -1
  // @ts-ignore
  const el: HTMLLinkElement = document.getElementById('favicon')
  if (isStage) {
    if (el) {
      el.setAttribute('href', '/favicon-stage.png')
    } else {
      const el = document.createElement('link')
      el.rel = 'icon'
      el.type = 'image/ico'
      el.href = '/favicon-stage.png'
      el.id = 'favicon'
      document.head.append(el)
    }
  }
}

setFavicon()

const StudioProvider = Helpers.React.StudioProvider

const ProfiledHostview = Sentry.withProfiler(HostView, { name: 'HostView' })

const Content = () => {
  return (
    <StudioProvider>
      <AppProvider userType={UserType.HOST}>
        <UserProvider>
          <PermissionProvider>
            <GuestChatProvider>
              <ProfiledHostview />
            </GuestChatProvider>
          </PermissionProvider>
        </UserProvider>
      </AppProvider>
    </StudioProvider>
  )
}

ReactDOM.render(<Content />, document.getElementById('root'))
