import React, { useState, useEffect } from 'react'
import { Flex, FlexProps } from '../layout/Box'
import { useTimeout } from '../hooks/useTimeout'

type Props<
  T extends
    | { [key: number]: React.ReactNode }
    | { [key: string]: React.ReactNode },
> = {
  // Accepts an array or key-value pair
  items: T
  active: keyof T
} & FlexProps

export const Swapper = <T extends {}>({ items, active, ...flexProps }: Props<T>) => {
  const item = items[active]
  return (
    <>
      {item && (
        <Flex
          {...{
            width: '100%',
            height: '100%',
            ...flexProps,
          }}
        >
          {item}
        </Flex>
      )}
    </>
  )
}

const BlurrySwapper = <T extends {}>(
  props: Props<T> & { children: React.ReactChild },
) => {
  const { children, items, active, ...flexProps } = props
  const [hiding, setHiding] = useState(null)
  const [overlay, setOverlay] = useState(items[active])

  useEffect(() => {
    if (!items[active]) {
      setHiding(overlay)
      setOverlay(null)
    } else {
      setOverlay(items[active])
    }
  }, [active])

  useTimeout(
    () => {
      setHiding(null)
    },
    200,
    [hiding],
  )

  return (
    <Flex
      position="relative"
      {...(hiding || overlay ? { overflow: 'hidden' } : {})}
      {...flexProps}
    >
      <Flex
        align="center"
        justify="center"
        width="100%"
        height="100%"
        style={{
          filter: overlay ? 'blur(20px)' : '',
          opacity: overlay ? 0.7 : 1,
          transform: overlay ? `scale(${overlay ? 0.95 : 1})` : '',
          transition:
            '200ms ease opacity, 200ms ease transform, 200ms ease filter',
        }}
      >
        {children}
      </Flex>
      <Flex
        align="center"
        justify="center"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          transition: '200ms ease opacity, 200ms ease transform',
          opacity: overlay ? 1 : 0,
          transform: `scale(${overlay ? 1 : 0.9})`,
          pointerEvents: overlay ? 'all' : 'none',
        }}
      >
        {overlay || hiding}
      </Flex>
    </Flex>
  )
}

export { BlurrySwapper }
export default Swapper
